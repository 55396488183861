import React from "react";
import { useEffect, useState } from "react";
import logo from "../Assets/imgs/logo.webp";
import mainimage from "../Assets/imgs/main-image.webp";
import contentimg from "../Assets/imgs/main-content.webp";
import "../styles/Landing.css";
type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  inputRef: React.RefObject<HTMLInputElement>[];
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  error: string;
  showError: boolean;
  handleSubmitBtn: () => void;
  showLoader: boolean;
  timer: number;
  onResendOtpPressed: () => void;
};
const Otp = (props: Props) => {
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (!showResendButton) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setShowResendButton(true);
            clearInterval(countdown);
            return prevTimer;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdown) {
        clearInterval(countdown);
      }
    };
  }, [showResendButton]);

  return (
    <div className="landing-bg">
      <div className="subscrption-content">
        <div className="subscrption-inner">
          <div className="subscrption-logo">
            <img src={logo} alt="logo image" width={106} height={70} />
          </div>
          <div className="content-image">
            <img src={contentimg} alt="Content Image" />
          </div>
          <div className="image">
            <img src={mainimage} alt="image" />
          </div>
          <div className="form">
            <div className="input-content">
              <div className="text">Please enter OTP received via SMS</div>
              <div className="otp-inputs">
                <input
                  type="tel"
                  name="one"
                  maxLength={1}
                  autoFocus
                  value={props.otp.one}
                  onChange={(e) => {
                    props.handleChangeOTP(e, 0);
                  }}
                  ref={props.inputRef[0]}
                  onKeyDown={(e) => {
                    props.handleBackspace(e, 0);
                  }}
                />
                <input
                  type="tel"
                  name="two"
                  maxLength={1}
                  value={props.otp.two}
                  onChange={(e) => {
                    props.handleChangeOTP(e, 1);
                  }}
                  ref={props.inputRef[1]}
                  onKeyDown={(e) => {
                    props.handleBackspace(e, 1);
                  }}
                />
                <input
                  type="tel"
                  name="three"
                  maxLength={1}
                  value={props.otp.three}
                  onChange={(e) => {
                    props.handleChangeOTP(e, 2);
                  }}
                  ref={props.inputRef[2]}
                  onKeyDown={(e) => {
                    props.handleBackspace(e, 2);
                  }}
                />
                <input
                  type="tel"
                  name="four"
                  maxLength={1}
                  value={props.otp.four}
                  onChange={(e) => {
                    props.handleChangeOTP(e, 3);
                  }}
                  ref={props.inputRef[3]}
                  onKeyDown={(e) => {
                    props.handleBackspace(e, 3);
                  }}
                />
              </div>
            </div>

            {props.showError && <p className="error">{props.error}</p>}

            <div className="resend-code">
              {showResendButton ? (
                <div className="timer-text">
                  Didn’t Receive OTP?{" "}
                  <span onClick={props.onResendOtpPressed}>Resend OTP</span>
                </div>
              ) : (
                <div className="not-received">
                  00:{timer < 10 ? `0${timer}` : timer}
                </div>
              )}
            </div>

            {props.showLoader ? (
              <button className="lp-loader">Loading...</button>
            ) : (
              <button
                onClick={() => {
                  props.handleSubmitBtn();
                }}
              >
                Subscribe Now
              </button>
            )}

            <div className="prepaid-text">
              Join Now: 10 PKR / day (Inc tax).
            </div>

            <div className="condition">
              <a href="/terms">Terms & Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
