import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tagManagerEvents from "../utils/GoogleTagManager";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import Awareness from "../components/Awareness";

type Props = {
  token: string | null;
};
let adNetwork = "";

const AwarenessScreen = (props: Props) => {
  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  const getHe = () => {
    fetch(
      "http://billinglive.gamenow.com.pk/appgw/GetPartnerHEMSISDN?partnerId=digi-1b6ss430"
    )
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (data?.errorCode === "0" && data?.encMSISDN) {
          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const _utmSource: any = searchParams.get("utm_source");
          if (_utmSource) {
            checkUtm(_utmSource);
          }
          getFlow(_utmSource, data.encMSISDN);
        } 
      })
      .catch((error) => {
        // window.location.replace("http://apps.gamenow.com.pk/store");
      });
  };

  const checkUtm = async (utmSource: string) => {
    try {
      let response = await fetchApiGet(props.token, ApiName.utmList);
      console.log("UTM response", response);
      if (response.status === 0) {
        response.response.map((e: any) => {
          if (utmSource === e.sf_utm) {
            console.log("ad_network", e.ad_network);
            adNetwork = e.ad_network;
          }
        });
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getFlow = async (utmSource: string, msisdn: string | null) => {
    try {
      let data = {
        utm_source: utmSource,
        msisdn: msisdn,
      };
      let response = await fetchApiPost(props.token, ApiName.awarenessFlow, data);
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          tagManagerEvents("subscribe", "optimusTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        let url =`http://billinglive.gamenow.com.pk/appgw/digiredirect?msisdn=${response.msisdn}&resultCode=0`

        setTimeout(() => {
          window.location.replace(url);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return <Awareness />;
};

export default AwarenessScreen;
