import React, { useEffect, useState } from "react";
import Landingpage from "../components/Landing";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";

type Props = {
  token: string | null;
};

let heMsisdn = "";
const LandingPageScreen = (props: Props) => {
  const navigate = useNavigate();

  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [utmSource, setUtmSource] = useState<any>(null);

  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  // ----------------- Functions -----------------
  const getHe = () => {
    fetch(
      "http://billinglive.gamenow.com.pk/appgw/GetPartnerHEMSISDN?partnerId=digi-1b6ss430"
    )
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (data?.errorCode === "0" && data?.encMSISDN) {
          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const _utmSource: any = searchParams.get("utm_source");
          // const encodeMsisdn = encodeURIComponent(data.msisdn);
          setUtmSource(_utmSource);
          handleGetFlow(_utmSource, data.encMSISDN);
        } else {
          tagManagerEvents("wifiUser", utmSource);
          // setShowError(true);
          // setErrorMsg("Access to the ADX Game Now available for Jazz users.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleGetFlow = async (utmSource: string | null, msisdn: string) => {
    try {
      let _data = {
        msisdn: msisdn,
        utm_source: utmSource ? utmSource : "",
      };
      console.log(_data);
      let endpoint = ApiName.getFlow;
      let response = await fetchApiPost(props.token, endpoint, _data);

      if (response.status === 0) {
        if (
          response.response.isSpecialFlow === true &&
          response.response.msisdn !== "" &&
          response.response.otp !== ""
        ) {
          // special flow
          let _msisdn = "";
          if (response.response.msisdn.length === 12) {
            _msisdn = response.response.msisdn.substring(
              2,
              response.response.msisdn.length
            );
            heMsisdn = _msisdn;
            setPhoneNo(_msisdn);
          } else if (response.response.msisdn.length === 10) {
            heMsisdn = response.response.msisdn;
            setPhoneNo(response.response.msisdn);
          }
          const data = {
            isSpecialFlow: response.response.isSpecialFlow,
            fastFlow: response.response.fastFlow,
            msisdn: _msisdn,
            otp: response.response.otp,
            milliseconds: response.response.milliseconds,
            signIn: response.response.signIn,
            package: response.response.package,
            network: response.response.network,
            utmSource: utmSource,
          };

          navigate("/pin", { state: data });
        } else if (
          response.response.isSpecialFlow === false &&
          response.response.msisdn
        ) {
          // 4g user without special flow
          if (response.response.msisdn.length === 12) {
            let _msisdn = response.response.msisdn.substring(
              2,
              response.response.msisdn.length
            );
            heMsisdn = _msisdn;
            setPhoneNo(_msisdn);
            if (utmSource) {
              tagManagerEvents("heUser", utmSource);
            } else {
              tagManagerEvents("heUser", "");
            }
          } else if (response.response.msisdn.length === 10) {
            heMsisdn = response.response.msisdn;
            setPhoneNo(response.response.msisdn);
            if (utmSource) {
              tagManagerEvents("heUser", utmSource);
            } else {
              tagManagerEvents("heUser", "");
            }
          }
        } else {
          // wifi user
          if (utmSource) {
            tagManagerEvents("wifiUser", utmSource);
          } else {
            tagManagerEvents("wifiUser", "");
          }
        }
      }
    } catch (error) {
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setShowErrorMsg(false);
      setPhoneNo(e.target.value);
    } else {
    }
  };

  const onSendOtpPressed = async () => {
    try {
      if (phoneNo?.length === 10) {
        // send otp
        setShowLoader(true);

        let msisdn = {
          msisdn: `92` + phoneNo,
        };
        let response = await fetchApiPost(props.token, ApiName.sendOtp, msisdn);
        if (response.status === 0) {
          const data = {
            msisdn: phoneNo,
            otp: heMsisdn === phoneNo ? response.response : "",
            utmSource: utmSource,
          };
          navigate("/pin", { state: data });
        } else if (
          response.status === 1 &&
          response.response.toLowerCase() === "msisdn already active"
        ) {
          let url =
            "https://pk.playhood.mobi/subscription/login?msisdn=92" + phoneNo;
          // console.log("url ::", url);
          window.location.replace(url);
        } else {
          setShowErrorMsg(true);
          setErrorMsg("Something Went Wrong");
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setShowErrorMsg(true);
        setErrorMsg("Please Enter Valid 10 Digit Phone Number");
      }
    } catch (error) {
      console.log("sendotp", error);
      setShowErrorMsg(true);
      setErrorMsg("Something Went Wrong");
    }
  };

  return (
    <>
      <Landingpage
        handlePhoneNoChange={handlePhoneNoChange}
        phoneNo={phoneNo}
        handleOnPressOTPBtn={onSendOtpPressed}
        errorMsg={errorMsg}
        showErrorMsg={showErrorMsg}
        showLoader={showLoader}
      />
    </>
  );
};

export default LandingPageScreen;
