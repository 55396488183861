import "../styles/Terms.css";

function Terms() {
  return (
    <div className="jazz_discount_outer">
      <h2>
        AdvantageX Digital Pte Ltd. - Gamenow Premier League (GPL) Terms of use
      </h2>
      <div style={{ background: "rgba(0,0,0,0.8)", padding: "10px" }}>
        <p className="text-center">
          <b>
            AdvantageX Digital Pte Ltd. -<br /> Gamenow Premier League (GPL)
            <span className="block">Terms of use</span>
          </b>
        </p>
        <p>
          Hola, Salam! and very warm welcome to the Terms of use (
          <b className="bold">"Terms"</b>) of Advantagex Digital Pte Ltd. , a
          firm incorporated, with its registered office at 30 CECIL STREET,
          #19-08 PRUDENTIAL TOWER, SINGAPORE (049712) (
          <b className="bold">
            "Gaming Premiere League","GPL","We","Us", or "Our"
          </b>
          ). GPL provides HTML5 games based live tournaments. These games are
          either created by GPL or independent developers who sell/license their
          HTML5 games to GPL. These games run online within a browser and do not
          have to be downloaded or installed.
        </p>
        <p>
          By accessing or using the platform (defined below) you agree to be
          bound by these Terms. Your agreement with us includes these Terms, the
          Privacy Policy available at https://billinglive.gamenow.com.pk and the
          Cookie Policy available at https://static.GPL.com/cookie-policy.pdf
          (collectively “Agreement”).
        </p>

        <p>
          If you do not agree with these terms, then please refrain from using
          the platform. By accessing or using the platform, you irrevocably
          accept the agreement and agree to abide by the same ( as updated from
          time to time).
        </p>

        <p>
          If you have downloaded our PWA / App from Google Play Store, you will
          also be subject to Google Play Terms of Service. If there is any
          conflict between Google Play Terms of Service and this agreement with
          respect to your use of the App, then, this agreement shall prevail.
        </p>

        <p>
          <b className="sub-head">Definitions</b>
        </p>

        <p>
          <b className="bold"> “Subscription”</b> is an access model of the
          product where subscribers get access to the portal and tournaments
          hosted on the portal in which users compete to score in the Games
          hosted by GPL. The access depends on the subscription rules where
          entry fee and no of days of access is defined and is chosen by
          subscriber (if paid the subscription fee) or defined by GPL if ‘Free
          to play’ access. Generally subscription will require the user to pay
          an entry fee to subscribe. Free to play shall be as per the definition
          of GPL.
        </p>

        <p>
          <b className="bold">“Tournament”</b> is an event in which users
          compete to score in the Games hosted by GPL on their platform.
          Tournament can be either ‘Paid’ or ‘Free to play’. Paid Tournaments
          will require the player to pay an entry fee to participate. Free to
          play shall be open to all players.
        </p>

        <p>
          <b className="bold">“Player”</b> is a subscriber (defined below) who
          meets the eligibility criteria as detailed in Clause 4.1 (below) and
          participates in tournaments that are hosted by GPL on their platform.
        </p>

        <p>
          <b className="bold">“Subscriber”</b> is a user (defined below) who has
          paid a subscription fee as defined for the geo and has the access to
          the GPLs content.
        </p>

        <p>
          <b className="bold">“Currencies”</b> means the deposits or my play
          coins, winnings or reward coins or any adhoc give away (collectively).
        </p>

        <p>
          <b className="bold">“Deposits”or“DepositBalance”</b>or{" "}
          <b className="bold">“My Play Coins”</b> is the amount added by the
          player to his/her Account through his/her subscription fee payment
          through their mobile operator or their e-wallet or any other account.
        </p>

        <p>
          <b className="bold"> “Games”</b> shall mean the HTML5 games that are
          licensed / owned / developed by GPL and made available to users on the
          platform.
        </p>

        <p>
          <b className="bold"> “Personal Information”</b> means any information
          that relates to a natural person, which, either directly orindirectly,
          in combination withother information available or likely to be
          available with a body corporate, is capable of identifying such
          person.
        </p>
        <p>
          <b className="bold">“Platform”</b> means the website available at
          https:// billinglive.gamenow.com.pk and any subdomains or
          gamenow.com.pk and any subdomains(<b className="bold"> “website”</b>)
          and the GPL mobile application on Android (
          <b className="bold"> “Application”</b>).
        </p>
        <p>
          <b className="bold"> “Winnings”</b> is an award coin that may be given
          to a player at GPL’s sole discretion.
        </p>

        <p>
          <b className="bold">“Reward Coin”</b> or{" "}
          <b className="bold">“Winning Balance”</b> is an award given to a
          player for being a top scorer in a particular Tournament.
        </p>
        <p>
          <b className="bold"> “Territory”</b> shall mean Pakistan.
        </p>
        <p>
          <b className="bold"> “Billing Provider”</b> shall mean Jazz.
        </p>
        <p></p>
        <li>
          {" "}
          <b className=" bold">Parties to the Agreement</b>
          <br />
          These terms describe a contractual agreement between you, the user of
          the platform, and GPL regarding your use of the platform. GPL has the
          right to refuse access in its discretion for any or no reason. Without
          limiting the foregoing, you specifically acknowledge that GPL reserves
          the right to terminate or limit your access in the event that GPL
          determines, in its sole discretion, that you have violated the
          policies of GPL or any forumused by you, including the activities that
          adversely affect the experience of other users.
          <br />
        </li>

        <li>
          <b className="bold"> Eligibility Criteria for user(s)</b>
          You must be of legal age as applicable in local law or atleast 13
          years of age to access and use the GPL platform. If you are between
          the age of 13 to 17 years, your access to the platform should be
          permitted by your parent or legal guardian. you (or your
          parent/guardian) must agree to be bound by these terms, you must be a
          resident ofthe country the billing provider you have used to enter is
          operating in; If you do not meet any or all of these criteria, please
          do not use the GPL platform.
          <br />
        </li>

        <li>
          <b className="bold"> General Disclaimers</b>
          By use of the platform, you agree that you meet with the Eligibility
          Criteria as detailed in Clause 2 (above) and that you are fully able
          and competent to understand and accept this Agreement as a binding
          contract and to abide by all Terms. You shall not access the platform
          by any other means other than through the interfaces that are provided
          by GPL and not acquire, copy, or monitor any portion of the platform
          in any way to reproduce or circumvent the navigational structure or
          presentation of the platform, to obtain or attempt to obtain any
          materials, documents, or information through any means not
          specifically made available through the platform. You shall not host,
          display, upload, modify, publish, or share any information on the
          platform that:
          <ol type="a">
            <li>
              Belongs to another person to which you do not have any right to,or
            </li>
            <li>
              Is grossly harmful,blasphemous, defamatory,abscene,pornographic,
              paedophilic,libellous,invasive of another's
              privacy,hateful,racially abjectionable,disparaging,relating to
              encouraging money laundering or gambling or otherwise unlawful in
              any manner,or
            </li>
            <li>
              Engages in any activity that interferes with or disrupts access to
              the platform or GPL servers/networks,or
            </li>
            <li>
              Falsifies or deletes any author attributions, legal or other
              notices, proprietary designations,labels of the origin or source
              of software,or other material on the platform.
            </li>
          </ol>
          <br />
          GPL shall not be liable for any misuse of the personal information
          shared by you with us or a third party on your profile on the
          platform, chat rooms, forums, or game comments. GPL reserves the right
          to change the terms from time to time, and such changes will be come
          applicable to you if you continue to use the platform at any time
          after such changes are posted on the platform, where we believe that
          any changes are material, we will notify you through either an in-app
          notification, or SMS, or push notifications, or email you have
          provided us, but regardless of whether you receive such notice,any
          changes will become effective if you use any of the platform at any
          time after such changes are published on this page.We recommend that
          you visit this page periodically to be sure you are aware of the most
          recent Terms.
          <br />
          If you do not agree to these Terms, do not use any of the platform,
          and if you do not wish to agree to any changes to these Terms, please
          cease using theplatform.
          <br />
          GPL may organize Tournaments on the platform,and to participate in
          such Tournaments, the user need to be in compliance with these Terms,
          more particularly, Clause 4(below), and any additional criteria shared
          on the platform.
          <br />
        </li>

        <li>
          <b className="bold">Tournaments Terms and Conditions</b>
          The following terms and conditions govern the player’s participation
          in the tournaments hosted by GPL on its platform. Each tournament may
          have its own set of rules (“Rules”) and in the event of any conflict
          between these terms and the Rules of the relevant tournament, the
          rules shall supersede the terms.
          <br />
        </li>
        <li>
          {" "}
          <b className="bold">Eligibility Criteria(Players):</b>
          <br />
          You need to fulfil each of the following eligibility criteria to
          participate in a tournament.
          <ol type="a">
            <li>
              You should be an individual,resident ofthe country the billing
              provider is operating in.
            </li>
            <li>
              You should be of an age of 18 years or above at the time of entry
              into the tournament,you need to be a subscriber / user of our
              billing provider,
            </li>
            <li>You are not restricted by limited capacity.</li>
            <li>You are not a compulsive player.</li>
            <li>
              You are not depositing money originating from crime and/or
              unauthorized activities into your account,and
            </li>
            <li>
              You are not using your account to conduct any criminal / illegal
              activities.
            </li>
          </ol>
          GPL employees and their immediate family members (spouses,domestic
          partners,grand parents,siblings,children, and grand children) are not
          eligible to participate in tournaments.
          <br />
          <br />
          <b className="bold">Player Account:</b>
          <br />
          To participate in a tournament you must provide your mobile phone
          number registered with our billing partner. The mobile phone number
          shared by you, will be verified by GPL using mobile technology or else
          an One Time Password(<b className="bold">“OTP”</b>)system. Upon you,
          successfully verifying the OTP, an account will be created for you (
          <b className="bold"> “Account”</b>).
          <br />
          By registering for an account, you represent, warrant and agree that:
          <br />
          <ol type="1">
            <li>
              You meet with the eligibility criteria as detailed in Clause
              4.1(above).
            </li>
            <li>
              You are using your actual identity and a mobile phone number
              registered in your own name.
            </li>
            <li>
              You will provide only true,accurate,current, and complete
              information about yourself on the platform; and
            </li>
            <li>
              You will maintain and promptly update the information that you
              provide on the platform to keep it true, accurate, current, and
              complete.
            </li>
          </ol>
          <br />
          In course of the use of the platform, you may be asked to provide
          certain personal information to GPL. GPL’s information collection and
          use policies with respect to privacy of such information are set forth
          in the Privacy Policy. Your account is for your personal use only. You
          may not impersonate someone else. By creating an account after
          providing a mobile phone number and verifying it using an OTP , you
          represent, warrant, and agree that you are authorized to use the
          platform and participate in tournaments. You further agree that you
          will be solely responsible for the maintenance and security of your
          account. You also agree that you will be solely responsible for any
          activities conducted on or through your account regardless of whether
          or not you are the individual who undertakes such activities. This
          includes any unauthorized access and/or use of your account. If you
          detect any suspicious activity on your account, you will inform us
          immediately by writing an email to tech support@adxdigitalsg.com .
          <br />
          Accordingly, you release and hold harmless GPL from any and all claims
          and causes of action arising out of or resulting from a third party’s
          unauthorized access or use of your account. You shall not assign your
          rights and responsibilities pursuant to the agreement to any third
          party.
          <br />
          GPL reserves the right to deny or revoke your access to the platform,
          or deactivate your account completely, or any part thereof, at any
          time in its sole discretion, with or without cause. If you wish to
          discont inueusing your account, or if you become aware of any loss,
          theft, or unauthorized use of your account, please notify GPL
          immediately by sending mail to techsupport@adxdigitalsg.com
          <br />
          If you are dissatisfied with the platform, your soleremedy is to
          discontinueusing the platform.
          <br />
          <b className="bold">Tournament Disclaimers:</b>
          <br />
          The user must meet with the eligibility criteria as detailed in clause
          4.1. The Games are based on skills not chance. If you are not residing
          in the territory then please do not sign up for the tournament,any
          amount received by GPL as against the player deposit shall be
          non-refundable. The Players may use the currencies in their account to
          enter the tournament running on the platform,where the maximum
          participation limit has not been reached.
          <br />
          Players Requiring information on Tax or Legal issues are recommended
          to contact an advisor or the authorities in territory. <br /> <br />
          <b className="bold">Charges:</b>
          <br />
          The player shall belivable for all charges that billing provider may
          possibly debit for the transfer of monies between the player’s billing
          account and the platform.
          <br />
          <b className="bold">Transaction Charges</b>
          <br />
          The player gives his/her consent to direct debiting of the player’s
          billing account for any transaction charges. For promotional purposes,
          GPL may bear these transaction charges from time to time. However,
          such an event will not constitute a waiver of any subsequent
          transaction charge.
          <br />
          <b className="bold">Player - </b>
          <br /> Deposits, Tokens and winnings.
          <br />
          <b className="bold"> Purchase –</b> <br /> Players may purchase play
          coins through their billing account. Any Deposit to the Account of a
          player is non-refundable;
          <br />
          <b className="bold"> Entry Fee –</b> <br /> The play coins and reward
          coins may be used as entry fee for the paid tournaments.
          <br />
          <b className="bold"> Awards– </b>
          <br /> Awards for all Tournaments shall be declared in the Rules of
          such Tournament. All awards are either in the form of play coins or
          reward coins or any adhoc give away. GPL at its sole discretion
          reserves the right to provide players play coins or reward coins or
          any adhoc give away in the form of awards for performing identified
          tasks and activates on the platform i.e. activities like referring the
          platform to potential users, uploading GPL profile photos’.etc.
          <br />
          <b className="bold"> Redemption–</b>
          <br /> Only reward coins alone may be redeemed by the player from his/
          her GPL Account to his/ her respective linkedbilling provider account;
          <br />
          <b className="bold"> Expiry –</b>
          <br /> Currencies may expire based on the validity provided which will
          be nothing but duration of subscription including retrial period (
          retrial period an account is in will be sole decision of GPL);
          <br />
          <b className="sub-head">Player's Obligation</b>
          <br />
          <b className="bold">Player Shall:</b>
          <br />
          Ensure that he/she is compliant with the terms of any third party
          service provider facilitating the payments. Not be entitled to
          Currencies obtained through cheating or other breaches of the relevant
          Tournament Rules on the platform or these Terms, criminal activities,
          the manipulation of the gaming system, or by any other inappropriate
          means (<b className="bold"> “Cheating”</b>). Not pledge the Currencies
          in the player’s account, be solely responsible for any taxes which may
          arise in connection with encashing the currencies from the player’s
          GPL account to the player’s billing provider’s account,and be solely
          liable for all direct taxes under applicable and statutory laws, in
          connection with the encashment of reward coins by the player. The
          reward may be subject to withholding taxes and/or other levies, cess,
          etc. as per applicable law. GPL shall not be liable for payment of any
          tax on the reward.
          <br />
          <b className="sub-head">GPL’s Rights</b>
          <br />
          <b className="bold">GPL Shall:</b>
          <br />
          4.7.1. reserve the right to change the accepted method of payment or
          billing provider at its sole discretion; 4.7.2.not warrant that the
          method of payment is available at all times, Monitor all accounts and/
          or money transactions to prevent money laundering activities. Any
          suspicious activity may be reported to the relevantauthorities,
          reserve the right to verify the entitle mentof the player and, incase
          of doubt, may withhold the payment of the reward coins, not be liable
          to pay any interest against any balance amounts of REWARD COINS held
          in player’s Account on the platform for any period oftime, GPL
          reserves the right to amend the minimum deposit limit or arrange for
          additional and repeated examinations on deposits at its
          solediscretion; reserve the right to: <br />
          (i)limit player’s participation in the tournaments; and/or <br /> (ii)
          withhold any reward coins in the player’s account for a time period
          decided by GPL if such player is suspected of cheating not be
          responsible for any damages that could occur as a direct or indirect
          result of a delay or inability to complete payment or the redemption
          of the reward coins.
          <br />
          <b className="bold block">
            {" "}
            Termination / Suspension of the Player Account by the Player
          </b>
          <br />
          The player may at his/her will terminate or suspend his/her account by
          sending an mail to techsupport@adxdigitalsg.com. All Currencies in
          such terminated account shall stand for feited by you unless the
          player is able to withdraw the currencies in accordance with these
          terms. In the event of suspension of an account, the currencies shall
          be frozen and held in the Account for a period of 6 (six) months. If
          you fail to reactivate your account within the stipulated timeline,
          the currencies in your account shall be deemedforfeited.
          <br />
          <b className="bold block"> Tournament Entry Receipts</b>
          <p>
            GPL does not issue receipts for entries in the Tournaments. The
            entries are accounted within the Account on the platform. It is the
            responsibility of the player to ensure that the entries are
            accurate.The player may report any inaccuracies to
            techsupport@adxdigitalsg.com.
            <br />
          </p>
          <b className="bold block">Complaints</b>
          If any player does not receive reward coins in spite of being one of
          the winners in a Tournament (or if a player’s request to redeem
          winnings to his / her billing provider’s account fails), such player
          is required to submit an email to GPL on tech support@adxdigitalsg.com
          within 30 (thirty) days from the date on which such currencies were
          due to be credited in the player’s account. Failure to report the same
          within the stipulated timeline shall result in the player losing claim
          over such currencies.
          <br />
        </li>
        <br />

        <li>
          <b className="bold">Limitations on players</b>
          <br />
          <b className="block bold">General</b>
          Player undertakes to always follow the applicable gaming rules for all
          Games that are listed as part of the tournaments. Any breach of the
          gaming rules or the Terms will automatically mean that the players
          entry to the tournaments is declared void and the player will forfeit
          their entitlement to any winnings from any such void tournament.GPL
          reserves the right to declare a tournament void at any time. If the
          player discovers a bug or any other error in the system, he/she must
          immediately log out from the platform and write an email to GPL at
          techsupport@adxdigitalsg.com. The player may never profit from a bug
          or error in the platform. <br />
          <b className="block bold">Invalid players and forbiddenbehavior</b>
          The player must personally perform all actions in regards to the
          Account and all payment to the accounts hall be made solely for the
          benefitoftheplayer.
          <br />
          The following behavior, amongst other things, is strictly forbidden in
          relation to the Tournaments use or attempteduse, of external gaming
          assistance programs(EPA programs) including but not limited to,
          program or non-program based databases and profiles, for instance
          websites and subscription services created with the purpose of giving
          the player an unfairadvantage.
          <br />
          GPL reserves the right to declare the entry of a player void, entirely
          or partly, should it become evidentthat:
          <br />
          <ol type="a">
            <li>
              it has been offered, requested, or accepted on the grounds of an
              error;or
            </li>
            <li>
              theplayer or third parties acting in cooperation with the player
              jointly intend to evade the Agreement;or
            </li>
            <li>
              the player or other party acting in cooperation with the player
              may directly or indirectly influence the end result and/or the
              outcome of the Tournament.
            </li>
          </ol>
          <br />
          <b className="bold block"> No Refund </b>
          Any money added from the player’s billing provider’s account to the
          platform as Deposit is non-refundable. For clarity, even if the player
          is unable to participate in a Tournament due to network failure or any
          other computer related issue (in spite of having used PLAY COINS as
          the entry fee), there will be no refund of any deposit.
        </li>
        <br />
        <li>
          <b className="sub-head"> No Representations of Warranties</b>
          <br />
          User not limiting his/her statutory rights, expressly acknowledges and
          agrees to the following:
          <br />
          <ol type="a">
            <li>
              {" "}
              The player is participating in the tournament at his or her own
              risk;
            </li>
            <li>
              The platform is provided “asis”, GPL expressly disclaims all
              express or implied representations or warranties;
            </li>
            <li>There may be delays in GPL’s network and systems;and</li>
            <li>
              GPL reserves the right to publish the result of any/
              allTournaments.
            </li>
          </ol>
        </li>
        <br />

        <li>
          <b className="sub-head"> No Guarantees</b>
          <br />
          Among other things, GPL does not guarantee that:
          <br />
          <ol type="a">
            <li> The platform will meet the user’s expectations;or</li>
            <li>
              The platform will be accessible without interruption or in a
              timely, reliable, or fault- free manner;or
            </li>
            <li>
              The results obtained through use of the platform will be as per
              users expectations deemed as correct and reliable;or
            </li>
            <li>
              The quality of the products, services, information, or other
              material purchased or obtained by the user through the platform
              will meet the user’s expectations.
            </li>
          </ol>
          <br />
          The user shall be solely responsible for damages to his or her data
          system or for loss of data arising from downloads of content from the
          platform.
          <br />
          No guidance or information, written or oral, obtained from GPL or via
          the platform, shall constitute any warranty, which has not
          specifically been stated in these Terms.
        </li>
        <br />

        <li>
          <b className="sub-head"> Limitation of Liability</b>
          <br />
          Users specifically acknowledge and agree that in no event shall GPL be
          liable for any direct or indirect damage, including but not limited
          to, delay, damages caused by unused opportunity of the platform, loss
          of profits, goodwill, licenses of data, or other financial lossarising
          out of: theuse of the platform or inability to use the platform;c osts
          arisen from acquiring are placement of any product or service that is
          based on any item, data, information, or service purchased through the
          platform, from any message received through the platform or any
          transactions made by means of the platform; third party claims against
          the player; unlawful access to or modifications of data transmissions;
          or any other circumstances related to the platform.
          <br />
          GPL exempts itself from any claims for damages relating to the
          platform access, function,quality,possible errors such as spelling
          errors,bugs,and other factor srelating to the platform.
          <br />
          GPL shall not be liable for any indirect or unforeseeable damages
          caused to the users under any given circumstances.
          <br />
          The user(s) shall maintain confidentiality of his/her user credentials
          and shall be responsible for any access made through his/her account.
          The user shall immediately notify GPL of any actual or suspected
          unauthorized use of user’s credentials and may be liable for the GPL’s
          losses or such other parties as the case may be, due to any
          unauthorized use.
        </li>

        <li>
          <b className="sub-head"> Force Majeure</b> <br />
          GPL shall not beliable for any damages what so ever arisingout of
          forcemajeureor other similar circumstances, directly or indirectly
          affecting GPL and unreasonably complicating GPL’s activities. Examples
          of force majeure events are real or potential labour disputes,
          governmental actions, war or threat of war, sabotage, civil unrest,
          demonstrations, fire, storm, flooding, explosion, earthquake,
          provisions or limitations of materials or resources, inability to
          obtain the relevant authorization, accident, and defectin electricity
          or telecommunication network. For cemajeureor other event beyond GPL’s
          control hindering, delaying or complicating themaintenance of the
          platform entitles the GPL to suspend or limit the platform until
          further notice.
        </li>
        <br />

        <li>
          <b className="sub-head"> Intellectual Property Rights</b> <br />
          The content of the platform is protected by international copyright
          acts and treaties. All reproduction or distribution of any material on
          the platform, including but not limited to text, photographs, movies,
          music and software programs is strictly prohibited, unless explicitly
          stated otherwise. GPL reserves any and all rights not expressly
          granted herein.
          <br />
          GPL’s trade name, logotype, and all related trademarks, product and/or
          service names and slogans used in the platform are the property of GPL
          and may not be used in any way without the prior written approval of
          GPL. users use of the platform shall not be construedas the grant of
          any licens eorright to use any trademarks or names appearing on
          theplatform.
        </li>
        <br />

        <li>
          <b className="sub-head"> Advertisements</b>
          <br />
          GPL may include or offer third party products on the platform through
          digital advertisements, whether fetched directly from advertiser
          sorsourced from ad networks GPL shall not be liable for any privacy
          practices or the content shared by the business partners, advertisers,
          sponsors or other websites to which GPL provides links on the
          platform. The users shall be responsible for checking the privacy
          policy and terms of use of such third- parties before exploring their
          websites to safeguard their interests.
        </li>
        <br />

        <li>
          <b className="sub-head"> Term and Termination</b>
          <br />
          <b className="bold"> Term:</b> This Agreement is valid until further
          notice.
          <br />
          <b className="bold"> Termination:</b> player(s)whose Accounts have
          been suspended/ frozen/ terminated on the basis of the reason scited
          below, will not been titled to participate in any Tournament until GPL
          at its sole discretion lifts such suspension/freezing/termination.
          Freezing can occur in relation to the entire platform orcertain
          sections of the platform.
          <ol type="A">
            <li>
              GPL may at its soled iscretion and without prior notice, suspend/
              freeze/ terminate the user’s access to the platform if he/she
              violates or attempts to violate the security of the platform
              including, without limitation:
              <ol type="i">
                <li>
                  accessing data not intended for you or logging on to a server
                  or player Account the user is not authorised touse;
                </li>
                <li>
                  attempting to probe, scan or test the vulnerability of the
                  system or network or to breach security or authentication
                  measures without authentication;or
                </li>
                <li>
                  accessing or using the platform without authorisation, in
                  violation of these Terms or in violation of applicablelaw.
                </li>
              </ol>
              <br />
              Violations of system or network security may result in civil or
              criminal liability. GPL will investigate occurrences that may
              involve such violations and cooperate with law enforcement
              authorities in prosecuting users who are involved in such
              violations. you agree not to use any device, software, or routine
              to interfere or attempt to interfere with the proper working of
              this platform or any activity being conducted on thisplatform.
            </li>

            <li>
              GPL may at its sole discretion and without prior notice, suspend,
              freeze or terminate the player’s Account under the
              followingcircumstances:
              <br />
              <ol type="1">
                <li>
                  The player violates any or all terms of this Agreement or
                  instructions on theplatform;
                </li>
                <li>
                  {" "}
                  The player shares false information at the time of signing up
                  for the Tournament(s);
                </li>
                <li>
                  GPL has reason to suspect a crime, misuse, fraud, or attempt
                  of such in relation to use of theplatform;
                </li>
                <li>
                  The player accesses or tries to access the Tournament from any
                  unauthorised location(s);
                </li>
                <li>
                  The player obtains Currencies through cheating or other
                  breaches of the relevant game rules on the platform or these
                  terms criminal activities, the manipulation of the gaming
                  system, or by any other inappropriatemeans.
                </li>
                <li>
                  No transactions have been registered for the player Account
                  for a period of 24months;
                </li>
                <li>
                  GPL considers it to be necessary for safety reasons or GPL’s
                  reputation;or
                </li>
                <li>
                  {" "}
                  GPL is forced to do so pursuant to legislation or ruling of a
                  competent authority.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <b className="sub-head"> Indemnity </b>
          <br />
          The users shall indemnify, defend, and hold harmless GPL and its
          affiliates, ifany, from and against any and all losses, liabilities,
          claims, suits, proceedings, penalties, interests, damages, demands,
          costs, and expenses (including legal and statutory fees and
          disbursements in connection therewith and interest chargeable thereon)
          asserted against or incurred by GPL that arise out of, result from, or
          in connectionwith:
          <ol type="i">
            <li>the user’s breach of theseTerms;</li>
            <li>
              any claims made by any third party due to, or arising out of, or
              in connection with user’s use of GPL platform or participation in
              a Tournament on the platform or winning/claiming the reward of
              such Tournament;
            </li>
            <li>
              the user’s violation of any rights of another, including
              intellectual property rights;and
            </li>
            <li>he user’s violation of any applicablelaws.</li>
          </ol>
          Not with standing anything to the contrary in these Terms, in no event
          shall GPL and its affiliates, if any, be liable to the user or anyone
          claiming through the user in respect of any subject matter of these
          Terms or Tournament(s) under contract, negligence, strict liability or
          other legal or equitable theory for any special, incidental, indirect,
          consequential, exemplary orpunitive damages, lossofgoodwill,
          lossofrevenue, lossof opportunity, loss of anticipated profits,
          whatsoever, including those resulting fromloss of use, data or
          profits, whether or not foreseeable or whether or not GPL has been
          advised of the possibility of such damages, orbased on any the
          oryofliability, including breach of contract or warranty or negligence
          or any other claim arising out of or in connection with the use of or
          access of theplatform.
        </li>
        <br />

        <li>
          <b className="sub-head"> Applicable Law and Jurisdiction</b>
          <br />
          These Terms shall be governed by the laws of territory. You agree that
          GPL is solely based in Singapore, and nothings hallgiverise to
          personal jurisdiction over us in jurisdictions other than Singapore
          and you shall not raise any claims against us in any court or forum in
          any jurisdiction other than Singapore.
          <br />
          Any claim or dispute between the player and GPL will be entertained
          and tried solely and exclusively by a court of competent jurisdiction
          located in Singapore and no other place whatsoever.
        </li>
        <br />

        <li>
          <b className="sub-head">Legal Aspects</b>
          <br />
          GPL offers reward coinsin tournaments in games of skills.
        </li>
        <br />

        <li>
          <b className="sub-head"> Severability</b>
          <br />
          If, for any reason, acourt of competent juris diction finds any
          provision of the Terms, or portion there of, to be unenforceable, that
          portion shall be enforced to the maximum extent permissible so as to
          give effect to the intent of the parties as reflected by that
          provision. The remainder of the Terms shall continue in full force and
          effect.
        </li>
        <br />

        <li>
          <b className="sub-head"> Waiver</b> <br />
          No provision of this Agreement shall be deemed to be waived and no
          breach excused, unless such waiver or consent shall be in writing and
          signed by GPL.Anyconsent by GPL to, or a waiver by GPL of any breach
          by the user, whether expressed or implied, shall not constitute
          consent to, waiver of, or excuse for any other different or subsequent
          breach.
        </li>
        <br />

        <li>
          <b className="sub-head"> Games of Skill</b>
          <br />A “Game of Skill” is a game where the outcome is determined
          mainly by mental or physical skill rather than by chance. GPL does not
          support, endorse or offer to users ‘games of chance’ for money. It is
          the dominant element of skill or chance which determines the character
          of the game. The outcome or success in the games hosted on GPL
          platform is directly dependent on the player’s effort, performance and
          skill. player’s skill and knowledge of the game may be enhanced with
          familiarity and practice.
        </li>

        <p></p>
      </div>
    </div>
  );
}

export default Terms;
