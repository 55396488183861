import React from "react";
import "../styles/Landing.css";
import logo from "../Assets/imgs/logo.webp";
import mainimage from "../Assets/imgs/main-image.webp";
import contentimg from "../Assets/imgs/main-content.webp";

type Props = {
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNo: string;
  handleOnPressOTPBtn: () => Promise<void>;
  errorMsg: string;
  showErrorMsg: boolean;
  showLoader: boolean;
};

const Landing = (props: Props) => {
  return (
    <>
      <div className="landing-bg">
        <div className="subscrption-content">
          <div className="subscrption-inner">
            <div className="subscrption-logo">
              <img src={logo} alt="logo image" width={106} height={70} />
            </div>
            <div className="content-image">
              <img src={contentimg} alt="Content Image" />
            </div>

            <div className="image">
              <img src={mainimage} alt="image" />
            </div>
            <div className="form">
              <div className="input-content">
                <div className="text">Please input number to get OTP</div>
                <div className="code-number">
                  <div className="code">
                    <div className="fixed-cnt">+92</div>
                  </div>
                  <div className="input">
                    <input
                      type="tel"
                      placeholder="3xx-xxxx-xxx"
                      max={10}
                      value={props.phoneNo}
                      onChange={(e) => {
                        props.handlePhoneNoChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              {props.showErrorMsg && <p className="error">{props.errorMsg}</p>}

              {props.showLoader ? (
                <button>Loading...</button>
              ) : (
                <button
                  onClick={() => {
                    props.handleOnPressOTPBtn();
                  }}
                >
                  Send OTP
                </button>
              )}

              <div className="prepaid-text">
                Join Now: 10 PKR / day (Inc tax).
              </div>

              <div className="condition">
                <a href="/terms">Terms & Conditions</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
