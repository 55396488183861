export const ApiName = {
  generateToken: "authenticateWeb",
  getFlow: "getFlow",
  sendOtp:"sendOtpWeb",
  awarenessFlow:"awarenessFlow",
  subscribe: "subscribeWeb",
  ffSubscribe: "ffSubscribe",
  utmList:"utmList"

};
